<template>

 <v-app id="inspire">
   <nav>
    <v-toolbar>
     <v-toolbar-title class="headline text-uppercase">
     </v-toolbar-title>

                  <v-spacer></v-spacer>

            <img src="/img/logo.png" style="width: 164px;">

     <v-spacer></v-spacer>
   <div class="text-right mr-2">
      <v-btn  outlined color="primary" @click="$router.push('/login')">Login</v-btn>
      </div>
   </v-toolbar>
 </nav>
    <v-main>
   
      <v-container
        class="fill-height"
        fluid
      >
    
        <v-row
        class="px-auto mx-auto"
          align="center"
          justify="center"
        >

  
            <v-card class="elevation-12 fill-width" style="maxWidth: 600px;">
              <v-toolbar
                color="primary"
                id="LoginToolbar"
              >

                <v-toolbar-title style="color: white;">Register</v-toolbar-title>
              </v-toolbar>
                 
              <v-card-text>
                <v-form
                  ref="form"
                  lazy-validation>
                  <v-text-field
                    v-model="name"
                    prepend-icon="mdi-account"
                    :rules="[() => !!name || 'Name is required' ]"
                    label="Name/Business Name"
                    required
                  />
                  <v-text-field
                    ref="email"
                    v-model="email"
                    :rules="[() => !!email || 'E-mail is required', 
                            v => /.+@.+\..+/.test(v) || 'E-mail must be valid' ]"
                    prepend-icon="mdi-email"
                    label="Email"
                    required
                  />
                  <v-text-field
                    ref="password"
                    v-model="password"
                    :rules="[() => !!password || 'This field is required']"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showPassword ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    label="Password"
                    placeholder="*********"
                    required
                    @keydown.enter="login"
                    @click:append="showPassword = !showPassword"
                  />
                  <v-text-field
                    ref="confirm_password"
                    v-model="confirm_password"
                    :rules="[() => !!confirm_password  && confirm_password == password || 'Passwords must match']"
                    :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    label="Confirm Password"
                    placeholder="*********"
                    required
                    @click:append="showConfirmPassword = !showConfirmPassword"
                  />
                  <v-text-field
                    v-model="referral"
                    prepend-icon="mdi-account-multiple"
                    label="Referral code"
                  />
                  <v-row>
                    <v-col>
                  <v-select
                    :items="plans"
                    v-model="plan"
                    item-text="name"
                    item-value="code"
                    prepend-icon="mdi-account-multiple"
                    label="Plan"
                    dense
                    :rules="[() => !!plan || 'This field is required']"
                    required
                  ></v-select>
                    </v-col>
                    <v-col>

                      <v-select
                      label="Type"
                      dense
                      item-text="name"
                      item-value="code"
                      :rules="[() => !!type || 'Please choose a billing method.']"
                          required
                       v-model="type"
                       :items='[{name: "Monthly", code: "monthly"} , { name:"Yearly" , code:"yearly"}]'
                      >
                      </v-select>
                    </v-col>
              </v-row>
                  <v-radio-group 
                    :rules="[() => !!customer_type || 'This field is required']"
                    required
                    row 
                    v-model="customer_type">
                    <v-radio
                      label="Individual"
                      value="individual"
                    ></v-radio>
                       <v-radio
                      label="Business"
                      value="business"
                    ></v-radio>
                  </v-radio-group>
                   
                   <v-autocomplete
                      hide-details="true"
                      v-model="country"
                      :items="countries"
                      item-value="code"
                      item-text="name"
                      dense
                      chips
                      small-chips
                      label="Country"
                      solo
                      :rules="[() => !!country || 'This field is required']"
                      required
                    ></v-autocomplete>
                      <v-row>
                        <v-col>
                              <v-text-field
                                v-model="address"
                                :rules="[() => !!address || 'This field is required']"
                                prepend-icon="mdi-home"
                                label="Street"
                                required
                              />
                            </v-col>
                      </v-row>
                       <v-row>
                        <v-col>
                        
                                <v-text-field
                              v-model="city"
                              :rules="[() => !!city || 'This field is required']"
                              label="City"
                              required
                            />
                            </v-col>
                            <v-col>
                                <v-text-field
                              v-model="state"
                              label="State"
                              
                            />
                          
                            </v-col>
                         
                      </v-row>
                      <v-row>
                        <v-col> 
                          <v-text-field
                                v-model="zip_code"
                                :rules="[() => !!zip_code || 'This field is required']"
                                label="Zip Code"
                                required
                              />
                              </v-col>
                      </v-row>
                    

                    <v-text-field
                        v-if="customer_type == 'business' && isEuCountry()"
                        ref="vat"
                        v-model="vat_no"
                        :rules="[() => !!vat_no || 'Please enter a valid Vat Number']"
                        prepend-icon="mdi-lock"
                        label="Vat Number"
                        required
                    />

              
                  <v-row>

                  <v-col cols="1">
                  <v-checkbox v-model="terms" class="mt-0"
                    :rules="[() => terms == true && !!terms || 'Please accept the terms and conditions.']"
                  
                   required>
                  </v-checkbox>
                  </v-col>
                  <v-col>
                    I agree to the Terms of Service and Privacy Policy. I understand that my account will automatically renew, hence my payment method will be automatically charged until I cancel. Plan fees will include any optional add-ons I choose and will be charged after any free trial or promotional period.
                  </v-col>
                  </v-row>
                 
                  </v-form>
                    <p class="font-weight-light" v-if="payment_method == 'paypal'">
                      * Upon clicking "Register", you will be redirected to PayPal. You will automatically be registered for a 30 day trial period, after that expires you will get charged accordingly to the plan you have chosen.
                    </p>
                       <p class="font-weight-light" v-if="payment_method == 'stripe'">
                      * Your payment will be processed by Stripe.
                    </p>
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" @click="stripePayment">Next</v-btn>
              </v-card-actions>
            </v-card>
        </v-row>
        <v-snackbar
        v-model="snackbar"
        :color="color"
        :top='true'
      >
        {{ snackbarMessage }}
        <v-btn
          dark

          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
      
       <v-overlay
          v-if="overlay"
          :value="overlay"
          z-index=0 
         >

        <v-row class="text-center">
          <v-col text-center>
            <v-progress-circular
            align-center
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row>
                      Redirecting to stripe checkout...

          </v-row>
       </v-overlay>

      </v-container>
    </v-main>
  </v-app>
</template>

<style>

</style>
<script>
import { loadStripe } from "@stripe/stripe-js"

export default {

  data: function () {
    return {
      cardDetailsDialog: false,
      overlay: false,
      countries: [ 
                {name: 'Afghanistan', code: 'AF'}, 
                {name: 'Åland Islands', code: 'AX'}, 
                {name: 'Albania', code: 'AL'}, 
                {name: 'Algeria', code: 'DZ'}, 
                {name: 'American Samoa', code: 'AS'}, 
                {name: 'AndorrA', code: 'AD'}, 
                {name: 'Angola', code: 'AO'}, 
                {name: 'Anguilla', code: 'AI'}, 
                {name: 'Antarctica', code: 'AQ'}, 
                {name: 'Antigua and Barbuda', code: 'AG'}, 
                {name: 'Argentina', code: 'AR'}, 
                {name: 'Armenia', code: 'AM'}, 
                {name: 'Aruba', code: 'AW'}, 
                {name: 'Australia', code: 'AU'}, 
                {name: 'Austria', code: 'AT'}, 
                {name: 'Azerbaijan', code: 'AZ'}, 
                {name: 'Bahamas', code: 'BS'}, 
                {name: 'Bahrain', code: 'BH'}, 
                {name: 'Bangladesh', code: 'BD'}, 
                {name: 'Barbados', code: 'BB'}, 
                {name: 'Belarus', code: 'BY'}, 
                {name: 'Belgium', code: 'BE'}, 
                {name: 'Belize', code: 'BZ'}, 
                {name: 'Benin', code: 'BJ'}, 
                {name: 'Bermuda', code: 'BM'}, 
                {name: 'Bhutan', code: 'BT'}, 
                {name: 'Bolivia', code: 'BO'}, 
                {name: 'Bosnia and Herzegovina', code: 'BA'}, 
                {name: 'Botswana', code: 'BW'}, 
                {name: 'Bouvet Island', code: 'BV'}, 
                {name: 'Brazil', code: 'BR'}, 
                {name: 'British Indian Ocean Territory', code: 'IO'}, 
                {name: 'Brunei Darussalam', code: 'BN'}, 
                {name: 'Bulgaria', code: 'BG'}, 
                {name: 'Burkina Faso', code: 'BF'}, 
                {name: 'Burundi', code: 'BI'}, 
                {name: 'Cambodia', code: 'KH'}, 
                {name: 'Cameroon', code: 'CM'}, 
                {name: 'Canada', code: 'CA'}, 
                {name: 'Cape Verde', code: 'CV'}, 
                {name: 'Cayman Islands', code: 'KY'}, 
                {name: 'Central African Republic', code: 'CF'}, 
                {name: 'Chad', code: 'TD'}, 
                {name: 'Chile', code: 'CL'}, 
                {name: 'China', code: 'CN'}, 
                {name: 'Christmas Island', code: 'CX'}, 
                {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
                {name: 'Colombia', code: 'CO'}, 
                {name: 'Comoros', code: 'KM'}, 
                {name: 'Congo', code: 'CG'}, 
                {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
                {name: 'Cook Islands', code: 'CK'}, 
                {name: 'Costa Rica', code: 'CR'}, 
                {name: 'Cote D\'Ivoire', code: 'CI'}, 
                {name: 'Croatia', code: 'HR'}, 
                {name: 'Cuba', code: 'CU'}, 
                {name: 'Cyprus', code: 'CY'}, 
                {name: 'Czech Republic', code: 'CZ'}, 
                {name: 'Denmark', code: 'DK'}, 
                {name: 'Djibouti', code: 'DJ'}, 
                {name: 'Dominica', code: 'DM'}, 
                {name: 'Dominican Republic', code: 'DO'}, 
                {name: 'Ecuador', code: 'EC'}, 
                {name: 'Egypt', code: 'EG'}, 
                {name: 'El Salvador', code: 'SV'}, 
                {name: 'Equatorial Guinea', code: 'GQ'}, 
                {name: 'Eritrea', code: 'ER'}, 
                {name: 'Estonia', code: 'EE'}, 
                {name: 'Ethiopia', code: 'ET'}, 
                {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
                {name: 'Faroe Islands', code: 'FO'}, 
                {name: 'Fiji', code: 'FJ'}, 
                {name: 'Finland', code: 'FI'}, 
                {name: 'France', code: 'FR'}, 
                {name: 'French Guiana', code: 'GF'}, 
                {name: 'French Polynesia', code: 'PF'}, 
                {name: 'French Southern Territories', code: 'TF'}, 
                {name: 'Gabon', code: 'GA'}, 
                {name: 'Gambia', code: 'GM'}, 
                {name: 'Georgia', code: 'GE'}, 
                {name: 'Germany', code: 'DE'}, 
                {name: 'Ghana', code: 'GH'}, 
                {name: 'Gibraltar', code: 'GI'}, 
                {name: 'Greece', code: 'GR'}, 
                {name: 'Greenland', code: 'GL'}, 
                {name: 'Grenada', code: 'GD'}, 
                {name: 'Guadeloupe', code: 'GP'}, 
                {name: 'Guam', code: 'GU'}, 
                {name: 'Guatemala', code: 'GT'}, 
                {name: 'Guernsey', code: 'GG'}, 
                {name: 'Guinea', code: 'GN'}, 
                {name: 'Guinea-Bissau', code: 'GW'}, 
                {name: 'Guyana', code: 'GY'}, 
                {name: 'Haiti', code: 'HT'}, 
                {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
                {name: 'Holy See (Vatican City State)', code: 'VA'}, 
                {name: 'Honduras', code: 'HN'}, 
                {name: 'Hong Kong', code: 'HK'}, 
                {name: 'Hungary', code: 'HU'}, 
                {name: 'Iceland', code: 'IS'}, 
                {name: 'India', code: 'IN'}, 
                {name: 'Indonesia', code: 'ID'}, 
                {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
                {name: 'Iraq', code: 'IQ'}, 
                {name: 'Ireland', code: 'IE'}, 
                {name: 'Isle of Man', code: 'IM'}, 
                {name: 'Israel', code: 'IL'}, 
                {name: 'Italy', code: 'IT'}, 
                {name: 'Jamaica', code: 'JM'}, 
                {name: 'Japan', code: 'JP'}, 
                {name: 'Jersey', code: 'JE'}, 
                {name: 'Jordan', code: 'JO'}, 
                {name: 'Kazakhstan', code: 'KZ'}, 
                {name: 'Kenya', code: 'KE'}, 
                {name: 'Kiribati', code: 'KI'}, 
                {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
                {name: 'Korea, Republic of', code: 'KR'}, 
                {name: 'Kuwait', code: 'KW'}, 
                {name: 'Kyrgyzstan', code: 'KG'}, 
                {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
                {name: 'Latvia', code: 'LV'}, 
                {name: 'Lebanon', code: 'LB'}, 
                {name: 'Lesotho', code: 'LS'}, 
                {name: 'Liberia', code: 'LR'}, 
                {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
                {name: 'Liechtenstein', code: 'LI'}, 
                {name: 'Lithuania', code: 'LT'}, 
                {name: 'Luxembourg', code: 'LU'}, 
                {name: 'Macao', code: 'MO'}, 
                {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
                {name: 'Madagascar', code: 'MG'}, 
                {name: 'Malawi', code: 'MW'}, 
                {name: 'Malaysia', code: 'MY'}, 
                {name: 'Maldives', code: 'MV'}, 
                {name: 'Mali', code: 'ML'}, 
                {name: 'Malta', code: 'MT'}, 
                {name: 'Marshall Islands', code: 'MH'}, 
                {name: 'Martinique', code: 'MQ'}, 
                {name: 'Mauritania', code: 'MR'}, 
                {name: 'Mauritius', code: 'MU'}, 
                {name: 'Mayotte', code: 'YT'}, 
                {name: 'Mexico', code: 'MX'}, 
                {name: 'Micronesia, Federated States of', code: 'FM'}, 
                {name: 'Moldova, Republic of', code: 'MD'}, 
                {name: 'Monaco', code: 'MC'}, 
                {name: 'Mongolia', code: 'MN'}, 
                {name: 'Montserrat', code: 'MS'}, 
                {name: 'Morocco', code: 'MA'}, 
                {name: 'Mozambique', code: 'MZ'}, 
                {name: 'Myanmar', code: 'MM'}, 
                {name: 'Namibia', code: 'NA'}, 
                {name: 'Nauru', code: 'NR'}, 
                {name: 'Nepal', code: 'NP'}, 
                {name: 'Netherlands', code: 'NL'}, 
                {name: 'Netherlands Antilles', code: 'AN'}, 
                {name: 'New Caledonia', code: 'NC'}, 
                {name: 'New Zealand', code: 'NZ'}, 
                {name: 'Nicaragua', code: 'NI'}, 
                {name: 'Niger', code: 'NE'}, 
                {name: 'Nigeria', code: 'NG'}, 
                {name: 'Niue', code: 'NU'}, 
                {name: 'Norfolk Island', code: 'NF'}, 
                {name: 'Northern Mariana Islands', code: 'MP'}, 
                {name: 'Norway', code: 'NO'}, 
                {name: 'Oman', code: 'OM'}, 
                {name: 'Pakistan', code: 'PK'}, 
                {name: 'Palau', code: 'PW'}, 
                {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
                {name: 'Panama', code: 'PA'}, 
                {name: 'Papua New Guinea', code: 'PG'}, 
                {name: 'Paraguay', code: 'PY'}, 
                {name: 'Peru', code: 'PE'}, 
                {name: 'Philippines', code: 'PH'}, 
                {name: 'Pitcairn', code: 'PN'}, 
                {name: 'Poland', code: 'PL'}, 
                {name: 'Portugal', code: 'PT'}, 
                {name: 'Puerto Rico', code: 'PR'}, 
                {name: 'Qatar', code: 'QA'}, 
                {name: 'Reunion', code: 'RE'}, 
                {name: 'Romania', code: 'RO'}, 
                {name: 'Russian Federation', code: 'RU'}, 
                {name: 'RWANDA', code: 'RW'}, 
                {name: 'Saint Helena', code: 'SH'}, 
                {name: 'Saint Kitts and Nevis', code: 'KN'}, 
                {name: 'Saint Lucia', code: 'LC'}, 
                {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
                {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
                {name: 'Samoa', code: 'WS'}, 
                {name: 'San Marino', code: 'SM'}, 
                {name: 'Sao Tome and Principe', code: 'ST'}, 
                {name: 'Saudi Arabia', code: 'SA'}, 
                {name: 'Senegal', code: 'SN'}, 
                {name: 'Serbia and Montenegro', code: 'CS'}, 
                {name: 'Seychelles', code: 'SC'}, 
                {name: 'Sierra Leone', code: 'SL'}, 
                {name: 'Singapore', code: 'SG'}, 
                {name: 'Slovakia', code: 'SK'}, 
                {name: 'Slovenia', code: 'SI'}, 
                {name: 'Solomon Islands', code: 'SB'}, 
                {name: 'Somalia', code: 'SO'}, 
                {name: 'South Africa', code: 'ZA'}, 
                {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
                {name: 'Spain', code: 'ES'}, 
                {name: 'Sri Lanka', code: 'LK'}, 
                {name: 'Sudan', code: 'SD'}, 
                {name: 'Suriname', code: 'SR'}, 
                {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
                {name: 'Swaziland', code: 'SZ'}, 
                {name: 'Sweden', code: 'SE'}, 
                {name: 'Switzerland', code: 'CH'}, 
                {name: 'Syrian Arab Republic', code: 'SY'}, 
                {name: 'Taiwan, Province of China', code: 'TW'}, 
                {name: 'Tajikistan', code: 'TJ'}, 
                {name: 'Tanzania, United Republic of', code: 'TZ'}, 
                {name: 'Thailand', code: 'TH'}, 
                {name: 'Timor-Leste', code: 'TL'}, 
                {name: 'Togo', code: 'TG'}, 
                {name: 'Tokelau', code: 'TK'}, 
                {name: 'Tonga', code: 'TO'}, 
                {name: 'Trinidad and Tobago', code: 'TT'}, 
                {name: 'Tunisia', code: 'TN'}, 
                {name: 'Turkey', code: 'TR'}, 
                {name: 'Turkmenistan', code: 'TM'}, 
                {name: 'Turks and Caicos Islands', code: 'TC'}, 
                {name: 'Tuvalu', code: 'TV'}, 
                {name: 'Uganda', code: 'UG'}, 
                {name: 'Ukraine', code: 'UA'}, 
                {name: 'United Arab Emirates', code: 'AE'}, 
                {name: 'United Kingdom', code: 'GB'}, 
                {name: 'United States', code: 'US'}, 
                {name: 'United States Minor Outlying Islands', code: 'UM'}, 
                {name: 'Uruguay', code: 'UY'}, 
                {name: 'Uzbekistan', code: 'UZ'}, 
                {name: 'Vanuatu', code: 'VU'}, 
                {name: 'Venezuela', code: 'VE'}, 
                {name: 'Viet Nam', code: 'VN'}, 
                {name: 'Virgin Islands, British', code: 'VG'}, 
                {name: 'Virgin Islands, U.S.', code: 'VI'}, 
                {name: 'Wallis and Futuna', code: 'WF'}, 
                {name: 'Western Sahara', code: 'EH'}, 
                {name: 'Yemen', code: 'YE'}, 
                {name: 'Zambia', code: 'ZM'}, 
                {name: 'Zimbabwe', code: 'ZW'} 
              ],
      eu_countries: [	'AT',
                      'BE',
                      'BG',
                      'HR',
                      'CY',
                      'CZ',
                      'DK',
                      'EE',
                      'FI',
                      'FR',
                      'DE',
                      'EL',
                      'HU',
                      'IE',
                      'IT',
                      'LV',
                      'LT',
                      'LU',
                      'MT',
                      'NL',
                      'PO',
                      'PT',
                      'RO',
                      'SK',
                      'SI',
                      'ES',
                      'SW',
                      'GB',
      ],
  
      type: "",
      name: '',
      email: '',
      password: '',
      confirm_password: '',
      referral: '',
      plan:  '',
      customer_type: '',
      vat_no: '',
      country: '',
      city: '',
      state: '',
      zip_code: '',
      address: '',
      payment_method: 'stripe',
      payment_methods: [
          {name: "Credit Card" , code: "stripe"},
          {name: "PayPal" , code: "paypal"} 
          ],
      terms: false,
   
      errors: {
        email: ''
      },
      payment_total: "",
      payment_vat: "",
      payment_plan: "",
      plans: [
       { name: "Basic 1" ,
          code: "Basic1"
       },
       { name: "Basic 2" ,
         code: "Basic2"
       },
       { name: "Basic 3" ,
          code: "Basic3"
       },
       { name: "Business 1" ,
         code: "Business1"
        },
        { name: "Business 2" ,
            code: "Business2"
        },
        { name: "Business 3" ,
           code: "Business3"
        },
        { name: "Enterprise" ,
           code: "Enterprise"
        },

      ],
      snackbarMessage: '',
      snackbar: false,
      color: 'general',
      showPassword: false,
      showConfirmPassword: false
    }
  },

  mounted () {
    if(this.$route.query.plan){
          this.plan = this.$route.query.plan

    }
    if(this.$route.query.type) {
          this.type = this.$route.query.type
    }

  },

  methods: {
    
    luhnCheck:  function (sixteenDigitString) {
        var numSum = 0;
        var value;
        for (var i = 0; i < 19; ++i) {
            if (i % 2 == 0) {
                value = 2 * sixteenDigitString[i];
                if (value >= 10) {
                    value = (Math.floor(value / 10) + value % 10);
                }
            } else {
                value = +sixteenDigitString[i];
            }
            numSum += value;
        }
        return (numSum % 10 == 0);
    },
    stripePayment: function (){
      if(this.$refs.form.validate()) {

              this.overlay = true
      //Verify all card data is there and validated

      // if(this.$refs.paymentForm.validate()) { 
         //Call stripe api for payment and add data to our DB 
         //Register user details in our DB
              let data = {
              name: this.name,
              email: this.email,
              password: this.password,
              referral: this.referral,
              plan: this.plan,
              customer_type: this.customer_type,
              city: this.city,
              country: this.country,
              address: this.address,
              zip_code: this.zip_code,
              state: this.state,
              type: this.type,
              vatNumber: this.vat_no

              
            }
        console.log(data)
         this.$store.dispatch('stripePayment' , data)
            .then(resp => {
              console.log(resp.data)
              const stripeInit = loadStripe(process.env.VUE_APP_STRIPE_KEY) 

               stripeInit.then(stripe => {
                  stripe.redirectToCheckout({
                    sessionId: resp.data.sessionId
                  }).then(function(result) {
                    console.log(result);
                  }).catch(function(error) {
                    console.error(error);
                  });
                })
            })
            .catch(err => {
               //checkout failed
              this.snackbarMessage = err.response.data;
              this.snackbar = true
              this.overlay = false

            })


      // }
      }else{
        this.snackbarMessage = "Kindly fill in all the fields";
        this.snackbar = true
      }

    },
    next: function () {
      if(this.$refs.form.validate()) {

        if(this.payment_method == "stripe"){
                  this.stripePayment()

        }else{
              let data = {
              name: this.name,
              email: this.email,
              password: this.password,
              referral: this.referral,
              plan: this.plan
            }
            // this.$store.dispatch('register', data)
            // .then(() => this.$router.push('/login'))
            // .catch(err =>  {
            //   this.errorMessage=err.response.data.msg
            //   this.snackbar= true
            // })

            this.$store.dispatch('paypalCheckout' , data)
            .then(resp => {
              // window.open(resp.data)
              console.log(resp.data)
              window.location.href = resp.data;

            })
            .catch(err => {

              this.snackbarMessage = err.response.data;
              this.snackbar = true
              //paypal checkout failed
            })

          }
      }
  },
  isEuCountry(){
    var country = this.country
    if(this.eu_countries.includes(country)){
      return true
    }else{
      return false
    }
  },
 
  isVatValid () {
     if(this.country && this.plan && this.customer_type  && this.vat_no){
      this.$store.dispatch('validateVatNo', {countryCode:  this.country, vatNumber: this.vat_no})
          .then(res => {
            console.log(res)
          })
          .catch(err => {
            console.log(err)
          })
       }
  
  },
 

  paypalCheckout() {
    let data = {
      name: this.name,
      email: this.email,
      password: this.password,
      referral: this.referral,
      plan: this.plan
    }

    this.$store.dispatch('paypalCheckout' , data)
    .then(resp => {
      // window.open(resp.data)
      console.log(resp.data)
      window.location.href = resp.data;

    })
    .catch(err => {
      this.model.errors.email = err.response.data;
      //paypal checkout failed
    })
    // this.$store.dispatch('paypalCheckout', plan)
    // .then(res =>
    //   window.open(res.paymentLink, "_blank")
    //
    //   // this.$router.push('/dashboard')
    // )
    // .catch(err => alert(err))
  }
  },
  metaInfo () {
    return {
      title: 'Cubixd | Register'
    }
  }
}
</script>
